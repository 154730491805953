import React from "react"


export default function Footer() {
    const date = new Date()
    return (
        <footer className="footer">
            <small>&copy; {date.getFullYear()} SynerGen Technologies S.L. All rights reserved.</small>
        </footer>
    )
}