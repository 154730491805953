import React from "react"


function ButtonScheduleCall(props, ref) {
    
    const { className, ...rest } = props;

    const buttonClassName = className ? `button_call ${className}` : `button_call`

    return (
        <button ref={ref} className={buttonClassName} {...rest}>Coming soon</button>
    )
}

export default React.forwardRef(ButtonScheduleCall);