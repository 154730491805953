import React from "react"
import arrow from "../images/arrow.png"

export default function MainSection2() {
    return (
        <div className="main_section2">
            <section className="main_section2_content">
                <div className="benefits_section">
                    <div className="text_left">
                        <h3>Streamline collaboration</h3>
                        <p>Export reports for tax, interest payments for accounting, graphs for internal presentations, and more.</p>
                    </div>
                    <img src={arrow} alt="" className="arrow_left_top" />
                </div>   
                <div className="benefits_section">
                    <img src={arrow} alt="" className="arrow_right" />
                    <div className="text_right">
                        <h3>Take back control</h3>
                        <p>Want to update the function and risk analysis? Add a company to the cash pool? Change the spread allocation? Sure thing.</p>
                    </div>
                </div>
                <div className="benefits_section"> 
                    <div className="text_left">
                        <h3>Save time</h3>
                        <p>Instantly get your interest rates on a daily basis, so that you’re always compliant and can use your time on more fun things.</p>
                    </div>
                    <img src={arrow} alt="" className="arrow_left_bottom" />
                </div> 
            </section>
        </div>
    )
}