import React from "react"
import Header from "./Header"
import MainSection from "./MainSection"
import Footer from "./Footer"

export default function Home() {
    return (
        <div className="container">
            <Header />
            <MainSection />
            <Footer />
        </div>
    )
}
