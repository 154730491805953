import React from "react"
import SynerGenLogo from "../images/snrgn_wo_bg_narrow.png"


export default function Header() {
    return (
        <header>
            <nav className="nav">
                <img src={SynerGenLogo} alt="" className="logo" />
                <ul className="nav-items">
                </ul>
            </nav>
        </header>
    )
}
