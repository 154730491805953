import React from "react"
import MainSection1 from "./MainSection1"
import MainSection2 from "./MainSection2"
import MainSection3 from "./MainSection3"

export default function MainSection() {
    return (
        <div className="main">
            <MainSection1 />
            <MainSection2 />
            <MainSection3 />
        </div>
    )
}